import React, { Component } from 'react';
import { PulseLoader as LoadingSpinner } from 'react-spinners'

class Loading extends Component {

    render() {
        const backgroundImage = this.props.backgroundImage;
        const primaryColor = this.props.primaryColor || "#000";
        return (
            <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
                <div className="loader">
                    <LoadingSpinner color={primaryColor} loading={true} />
                </div>
            </div>
        )
    }
}

export default Loading;
